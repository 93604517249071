import "firebase/auth";
import "firebase/functions";

import config from "~/config";
import firebase from "firebase/app";

firebase.initializeApp(config.firebase);

if (config.env === "local") {
  firebase.auth().useEmulator(config.firebase.emulator.auth.url);
  firebase
    .functions()
    .useEmulator(
      config.firebase.emulator.functions.host,
      config.firebase.emulator.functions.port
    );
}
