import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    fontWeight: "bold",
  },
}));

export default function Branding({ className, ...props }) {
  const classes = useStyles();

  return (
    <Typography
      className={clsx(classes.root, className)}
      component="h1"
      variant="h2"
      {...props}
    >
      Happy Balloon
    </Typography>
  );
}
