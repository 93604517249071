import * as Sentry from "@sentry/gatsby";
import firebase from "firebase/app";
import React from "react";

import AuthContext from "./AuthContext";

export default function AuthProvider({ children, ...props }) {
  const [user, setUser] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();

  const signOut = React.useCallback(async () => {
    await firebase.auth().signOut();
  }, []);

  const reportError = React.useCallback(
    (error) => {
      if (!error) {
        setError();
        return;
      }

      Sentry.captureException(error);
      setError(error);
      signOut();
    },
    [signOut]
  );

  const requestCustomToken = React.useCallback(async (clientId, publicKey) => {
    const functions = firebase.functions();

    return await functions.httpsCallable("requestCustomToken")({
      clientId,
      publicKey,
    });
  }, []);

  const signInWithGoogle = React.useCallback(async () => {
    try {
      setLoading(true);

      const provider = new firebase.auth.GoogleAuthProvider();

      provider.setCustomParameters({
        prompt: "select_account",
      });

      await firebase.auth().signInWithRedirect(provider);
    } catch (error) {
      reportError(error);
    } finally {
      setLoading(false);
    }
  }, [reportError]);

  const signInWithFacebook = React.useCallback(async () => {
    try {
      setLoading(true);

      const provider = new firebase.auth.FacebookAuthProvider();

      provider.addScope("email");

      await firebase.auth().signInWithRedirect(provider);
    } catch (error) {
      reportError(error);
    } finally {
      setLoading(false);
    }
  }, [reportError]);

  React.useEffect(
    function handleAuthStateChange() {
      return firebase.auth().onAuthStateChanged(
        async (user) => {
          try {
            setLoading(true);

            Sentry.setUser(
              user && {
                id: user.uid,
                username: user.displayName,
                email: user.email,
                ...user.toJSON(),
              }
            );

            setUser(user?.toJSON());
          } catch (error) {
            reportError(error);
          } finally {
            setLoading(false);
          }
        },
        (error) => {
          reportError(error);
        }
      );
    },
    [signOut, reportError, requestCustomToken]
  );

  React.useEffect(() => {
    async function updateCurrentUser() {
      if (user) {
        console.info(firebase.auth().currentUser);
        await firebase.auth().currentUser.reload();
      }
    }

    updateCurrentUser();
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!user,
        user,
        loading,
        error,
        requestCustomToken,
        signInWithGoogle,
        signInWithFacebook,
        signOut,
      }}
      {...props}
    >
      {children instanceof Function ? (
        <AuthContext.Consumer>{children}</AuthContext.Consumer>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}
