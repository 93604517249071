import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useAuth } from "~/auth";
import React from "react";

const useStyles = makeStyles((theme) => ({
  list: {
    wordWrap: "break-word",
  },
}));

export default function UserMenu({ onClose, ...props }) {
  const classes = useStyles();

  const { user, signOut } = useAuth();

  const close = React.useCallback(() => {
    onClose?.call();
  }, [onClose]);

  const handleSignOut = React.useCallback(() => {
    signOut();
    close();
  }, [signOut, close]);

  return (
    <Popover getContentAnchorEl={null} onClose={close} {...props}>
      <List className={classes.list}>
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar src={user?.photoURL} />
          </ListItemAvatar>
          <ListItemText primary={user?.displayName} secondary={user?.email} />
        </ListItem>

        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </MenuItem>
      </List>
    </Popover>
  );
}
