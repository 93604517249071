// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCMjI3IFKqwgs4eJW-umQB-MPXuk-Fzhus",
  authDomain: "www.goyo.app",
  projectId: "happy-balloon-prd",
  storageBucket: "happy-balloon-prd.appspot.com",
  messagingSenderId: "806271618311",
  appId: "1:806271618311:web:52eba5eebb3799270dc9a2",
  measurementId: "G-KXCJK1Y71N",
};

export default firebaseConfig;
