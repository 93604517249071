import React from "react";

import getDownloadUrls from "./getDownloadUrls";

export default function useDoanloadInfo() {
  const [loading, setLoading] = React.useState(true);
  const [downloadUrls, setDownloadUrls] = React.useState();

  React.useEffect(() => {
    (async () => {
      const downloadUrls = await getDownloadUrls();

      setDownloadUrls(downloadUrls);
      setLoading(false);
    })();
  }, []);

  return { loading, downloadUrls };
}
