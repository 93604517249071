import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import fp from "lodash/fp";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: theme.zIndex.appBar,
    top: ({ size }) => size * 1.6,
    left: ({ size }) => size * 1.6,
    transform: "translateX(-50%) translatey(-50%) rotate(-45deg)",
    padding: ({ size }) => `${theme.spacing(0.2)}px ${size * 4}px`,
    fontSize: ({ size }) => size,
    fontWeight: "bold",
    pointerEvents: "none",
    "& *": {
      padding: 0,
    },
    backgroundColor: ({ env }) => {
      switch (env) {
        case "local":
          return "dimgray";
        case "dev":
          return theme.palette.secondary.main;
        case "stg":
          return theme.palette.primary.main;
        default:
          return theme.palette.error.main;
      }
    },
  },
}));

export default function EnvRibbon({ env, size = 16, className, ...props }) {
  const classes = useStyles({ env, size });

  if (env === "prd") {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)} {...props}>
      {fp.toUpper(env)}
    </div>
  );
}
