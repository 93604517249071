exports.onClientEntry = () => {
  const { env } = require("~/config");

  const desiredHostname = () => {
    switch (env) {
      case "prd":
        return "www.goyo.app";

      case "local":
        return "localhost";

      default:
        return `${env}.goyo.app`;
    }
  };

  const redirectUrl = () => {
    switch (env) {
      case "prd":
        return "https://www.goyo.app";

      case "local":
        return `${window.location.protocol}//localhost:${window.location.port}`;

      default:
        return `https://${env}.goyo.app`;
    }
  };

  if (window.location.hostname !== desiredHostname()) {
    window.location.replace(redirectUrl());
  }

  require("~/initializeFirebase");

  window.GIT_COMMIT_HASH = process.env.GIT_COMMIT_HASH;
};
