import {
  Button,
  ButtonGroup,
  Fade,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  SvgIcon,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import GetAppIcon from "@material-ui/icons/GetApp";
import AppleIcon from "~/images/apple.svg";
import WindowsIcon from "~/images/windows.svg";
import Bowser from "bowser";
import clsx from "clsx";
import fp from "lodash/fp";
import React from "react";

function DownloadLabel({ os }) {
  switch (os) {
    case "macos":
      return "Download for Mac";
    case "windows":
      return "Download for Windows";
    default:
      return "Download not Available";
  }
}

function OsIcon({ os, size = 20, ...props }) {
  switch (os) {
    case "macos":
      return (
        <SvgIcon {...props}>
          <AppleIcon width={size} height={size} />
        </SvgIcon>
      );
    case "windows":
      return (
        <SvgIcon {...props}>
          <WindowsIcon width={size} height={size} />
        </SvgIcon>
      );
    default:
      return null;
  }
}

const useStyles = makeStyles((theme) => ({
  root: {},
  menuItemText: {
    margin: `0 ${theme.spacing(1)}px`,
  },
}));

export default function DownloadButton({
  className,
  loading,
  currentOs,
  downloadUrls,
  ...props
}) {
  const classes = useStyles();

  const [selectedOs, setSelectedOs] = React.useState();

  React.useEffect(() => {
    setSelectedOs(() => {
      const browser = Bowser.getParser(window.navigator.userAgent);
      const os = currentOs || browser.getOSName().toLowerCase();

      if ((os === "macos" || os === "ios") && downloadUrls?.macos) {
        return "macos";
      }

      if (downloadUrls?.windows) {
        return "windows";
      }

      if (downloadUrls?.macos) {
        return "macos";
      }

      return null;
    });
  }, [currentOs, downloadUrls]);

  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchor = React.useRef();

  const toggleMenu = React.useCallback(() => {
    setMenuOpen((menuOpen) => !menuOpen);
  }, []);

  const closeMenu = React.useCallback(() => {
    setMenuOpen(false);
  }, []);

  return (
    <>
      <Fade in={!loading}>
        <ButtonGroup
          ref={anchor}
          className={clsx(classes.root, className)}
          disabled={!downloadUrls?.[selectedOs]}
          color="primary"
          variant="contained"
          size="large"
          {...props}
        >
          <Button
            href={downloadUrls?.[selectedOs]}
            startIcon={<GetAppIcon />}
            endIcon={<OsIcon os={selectedOs} />}
          >
            <DownloadLabel os={selectedOs} />
          </Button>

          {fp.size(downloadUrls) > 1 && (
            <Button size="small" onClick={toggleMenu}>
              <ArrowDropDownIcon />
            </Button>
          )}
        </ButtonGroup>
      </Fade>

      <Popover
        anchorEl={anchor.current}
        getContentAnchorEl={null}
        open={fp.size(downloadUrls) > 1 && menuOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={closeMenu}
      >
        <MenuList dense>
          {fp.pipe(
            fp.keys,
            fp.filter((os) => os !== selectedOs),
            fp.map((os) => (
              <MenuItem
                key={os}
                component="a"
                href={downloadUrls?.[os]}
                onClick={closeMenu}
              >
                <GetAppIcon />
                <ListItemText
                  className={classes.menuItemText}
                  primary={<DownloadLabel os={os} />}
                />
                <OsIcon os={os} />
              </MenuItem>
            ))
          )(downloadUrls)}
        </MenuList>
      </Popover>
    </>
  );
}
