import firebaseConfig from "./firebase.dev";

const firebaseJson = require("~/../../firebase.json");

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...firebaseConfig,
  emulator: {
    auth: {
      url: `http://localhost:${firebaseJson.emulators.auth.port}`,
    },
    functions: {
      host: "localhost",
      port: firebaseJson.emulators.functions.port,
    },
  },
};
