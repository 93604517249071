// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCYv6v_EqWOxwN9fyXFEa3n8XrtFcr0ar8",
  authDomain: "dev.goyo.app",
  projectId: "happy-balloon-dev",
  storageBucket: "happy-balloon-dev.appspot.com",
  messagingSenderId: "218650289479",
  appId: "1:218650289479:web:05412e8dd568475ff01862",
  measurementId: "G-FFRZVPPVD2",
};

export default firebaseConfig;
