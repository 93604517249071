import config from "~/config";
import axios from "axios";
import fp from "lodash/fp";
import YAML from "yaml";

function getChannelInfoUrl(os, channel) {
  switch (os) {
    case "macos":
      return `${config.urls.releases}/${channel}-mac.yml`;
    case "windows":
      return `${config.urls.releases}/${channel}.yml`;
    default:
      return null;
  }
}

async function getDownloadUrl(os, channel = "latest") {
  try {
    const response = await axios.get(getChannelInfoUrl(os, channel));
    const channelInfo = YAML.parse(response.data);
    const fileInfo = fp.find(({ url }) => /(\.dmg|\.exe)$/.test(url))(
      channelInfo.files
    );

    return fileInfo && `${config.urls.releases}/${fileInfo.url}`;
  } catch (error) {
    switch (error.response?.status) {
      case 403:
      case 404:
        break;
      default:
        throw error;
    }

    switch (channel) {
      case "latest":
        return getDownloadUrl(os, "beta");
      case "beta":
        return getDownloadUrl(os, "alpha");
      default:
        throw error;
    }
  }
}

export default async function getDownloadUrls() {
  const [{ value: macos }, { value: windows }] = await Promise.allSettled([
    getDownloadUrl("macos"),
    getDownloadUrl("windows"),
  ]);

  return fp.pickBy(fp.identity)({ macos, windows });
}
