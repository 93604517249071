import { AuthProvider } from "~/auth";
import { EnvRibbon } from "~/components";
import { env } from "~/config";
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import React from "react";

export default function TopLayout({ theme, children }) {
  return (
    <AuthProvider>
      <ThemeTopLayout theme={theme}>
        <EnvRibbon env={env} />
        {children}
      </ThemeTopLayout>
    </AuthProvider>
  );
}
