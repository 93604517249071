// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDE0YRZfkCu35Qqvvf3FCw7mKQ_SFoDBkk",
  authDomain: "stg.goyo.app",
  projectId: "happy-balloon-stg",
  storageBucket: "happy-balloon-stg.appspot.com",
  messagingSenderId: "966292758941",
  appId: "1:966292758941:web:e48c810b591f3909ba0f74",
  measurementId: "G-JP3S8K1YCK",
};

export default firebaseConfig;
