import * as config from ".";

export const env = process.env.ENV || "local";

export const firebase = require(`./firebase.${env}`).default;

export const urls = (() => {
  switch (env) {
    case "local":
      return {
        releases: "http://localhost:8000/releases",
      };
    default:
      return {
        releases: `https://storage.googleapis.com/${firebase.storageBucket}/releases`,
      };
  }
})();

export const protocol = (() => {
  switch (env) {
    case "prd":
      return "happy-balloon";
    default:
      return `happy-balloon-${env}`;
  }
})();

export default config;
