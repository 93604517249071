import {
  Avatar,
  Button,
  Fade,
  IconButton,
  AppBar as MuiAppBar,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import { useAuth } from "~/auth";
import { UserMenu } from "~/components";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {},
  placeholder: {
    flexGrow: 1,
  },
  avatar: {
    width: 24,
    height: 24,
  },
}));

export default function AppBar({ className, ...props }) {
  const classes = useStyles();

  const { loading, authenticated, user } = useAuth();

  const menuAnchor = React.useRef();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const openMenu = React.useCallback(() => {
    setMenuOpen(true);
  }, []);

  const closeMenu = React.useCallback(() => {
    setMenuOpen(false);
  }, []);

  const [signInUrl, setSignInUrl] = React.useState();

  React.useEffect(() => {
    setSignInUrl(`/signin?redirectUrl=${escape(window.location.href)}`);
  }, []);

  return (
    <MuiAppBar
      className={clsx(classes.root, className)}
      position="fixed"
      color="transparent"
      elevation={0}
      {...props}
    >
      <Toolbar>
        <div className={classes.placeholder} />

        <Fade in={!loading}>
          {authenticated ? (
            <IconButton
              ref={menuAnchor}
              color="inherit"
              edge="end"
              onClick={openMenu}
            >
              <Avatar className={classes.avatar} src={user?.photoURL} />
            </IconButton>
          ) : (
            <Button
              href={signInUrl}
              variant="outlined"
              startIcon={<PersonIcon />}
            >
              Sign in
            </Button>
          )}
        </Fade>

        <UserMenu
          open={!loading && authenticated && menuOpen}
          anchorEl={menuAnchor.current}
          onClose={closeMenu}
        />
      </Toolbar>
    </MuiAppBar>
  );
}
