import {
  blue,
  cyan,
  deepOrange,
  green,
  orange,
  red,
} from "@material-ui/core/colors";

const palette = {
  type: "dark",
  primary: blue,
  secondary: deepOrange,
  error: red,
  warning: orange,
  info: cyan,
  success: green,
};

export default palette;
