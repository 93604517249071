import { env } from "~/config";
import { graphql, useStaticQuery } from "gatsby";
import fp from "lodash/fp";
import React from "react";
import { Helmet } from "react-helmet";

const query = graphql`
  query Seo {
    site {
      siteMetadata {
        defaultTitle: title
      }
    }
  }
`;

function getTitleWithEnv(title) {
  if (env === "prd") {
    return title;
  }

  return `${title} (${fp.toUpper(env)})`;
}

export default function Seo({ title, ...props }) {
  const { site } = useStaticQuery(query);

  const { defaultTitle } = site.siteMetadata;

  const seo = {
    title: getTitleWithEnv(title || defaultTitle),
  };

  return (
    <Helmet title={seo.title} {...props}>
      <meta property="og:title" content={seo.title} />
    </Helmet>
  );
}
